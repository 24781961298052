<template>
  <div class="page-content">
    <div class="row" style="height: auto !important">
      <div class="col-lg-9 col-md-9 col-sm-6 col-xs-6">
        <div class="card row">
          <div class="card-body col-12">
            <h4 class="mb-3">{{ page_title }}</h4>
            <b-form @submit.prevent="createBlog">
              <b-form-group label="Header">
                <el-input
                  v-model="v$.form.header.$model"
                  placeholder="Insert your title for header of the page"/>
                <b-form-invalid-feedback :state="!v$.form.header.$invalid" v-if="v$.form.header.$error">
                  {{ v$.form.header.$errors[0].$message }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Sub Header">
                <el-input
                  v-model="v$.form.sub_header.$model"
                  placeholder="Insert your sub header to describe the page"/>
                <b-form-invalid-feedback :state="!v$.form.sub_header.$invalid" v-if="v$.form.sub_header.$error">
                  {{ v$.form.sub_header.$errors[0].$message }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Page Content">
                <wysiwyg v-model="v$.form.content.$model" placeholder="Insert your content" />
                <b-form-invalid-feedback :state="!v$.form.content.$invalid" v-if="v$.form.content.$error">
                  {{ v$.form.content.$errors[0].$message }}
                </b-form-invalid-feedback>
              </b-form-group>
              <div class="d-flex float-right">
                <el-button @click="createBlog" class="btn mr-2 btn-primary">{{ $t('general.submit') }}</el-button>
                <el-button class="btn btn-secondary" @click="back">{{ $t('general.cancel') }}</el-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6 table-responsive">
        <div class="card row">
          <div class="card-body col-12">
            <h4 v-html="form.header"></h4>
            <p class="text-muted font-size-medium" v-html="form.sub_header"></p>
            <div class="view-content mt-3" v-html="form.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import channelsAPI from '../../../api/channels';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'EditWebchatProfile',
  data() {
    return {
      profile: '',
      configuration: {
        website_name: '',
        website_domain: '',
        widget_color: '',
        welcome_heading: '',
        welcome_tagline: '',
      },
      access_token: '',
      form: {
        header: '',
        sub_header: '',
        content: '',
      },
      page_title: this.$t('channels.blogs.btn_create_new'),
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        header: { required },
        sub_header: { required },
        content: { required },
      },
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  async mounted() {
    if (this.$route.name === 'Edit Blog' && this.$route.params._id) {
      this.editBlog();
    }
  },
  methods: {
    back() {
      this.$router.push(`/settings/channel/${this.$route.params.channel_id}/profile`);
    },
    editBlog() {
      this.page_title = this.$t('channels.blogs.btn_edit');
      this.form = {
        header: this.$route.params.header,
        sub_header: this.$route.params.sub_header,
        content: this.$route.params.description,
      };
    },
    async createBlog() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;

      const { form } = this;
      this.$parent.showLoader();
      let response = {};
      if (this.$route.name === 'Edit Blog' && this.$route.params._id) {
        response = await channelsAPI.editBlog(this.activeWorkspace._id, this.$route.params.channel_id, form, this.$route.params._id);
      } else {
        response = await channelsAPI.createBlog(this.activeWorkspace._id, this.$route.params.channel_id, form);
      }
      this.$parent.hideLoader();
      await popupErrorMessages(response).then(() => {
        this.$message({
          message: this.$t('channels.blogs.success_add'),
          type: 'success',
        });
        this.form = {
          header: '',
          sub_header: '',
          content: '',
        };
        this.$router.push(`/settings/channel/${this.$route.params.channel_id}/profile`);
      });
    },
    backChannelList() {
      this.$router.push({
        name: 'Channels',
      });
    },
  },
};
</script>

<style>
.editr--content img {
  width: 50%;
}
.view-content img {
  width: 100%;
}
</style>
